import React from 'react';
import styled from 'styled-components';
import Layout from "../components/layout";
import SectionFooter from '../components/sections/section-footer/SectionFooter';
import { theme } from "../utils/theme"
import {Grid} from '@material-ui/core';
import {Link} from 'gatsby';


const Container = styled.div`
  width: 100%;
  background-color: ${props => props.theme.colors.dark};
  overflow-y: visible;
`

const TitleCond = styled.h5`
  font-size: 3rem;
  font-weight: 300;
  width: 300px;
  line-height: 1.1;
  color: ${props => props.theme.colors.body};
  margin-bottom: 100px;
  text-align: left;
  padding-left: 80px;

  @media ${props => props.theme.breaks.down('md')} {
    padding-left: 0;
    margin-top: 5rem;
    margin-bottom: 5rem;
    font-size: 2.5rem;
  }
`

const StorySection = styled.div`
  min-height: 100vh;
  padding-top: 50px;
  text-align: center;

  @media ${props => props.theme.breaks.down('md')} {
    .title-cond {
      width: 90% !important;
    }
  }
`

const StoryContainer = styled.div`
  width: 70%;
  margin: 0 auto;
  margin-top: 7rem;
  position: relative;
  padding-left: 80px;


  .links {
    margin-top: 30px;
  }


  h5 {
    color: ${props => props.theme.colors.primary};
    text-align: left;
    margin-bottom: 30px;
  }

  p {
    color: ${props => props.theme.colors.primary};
    font-weight: 300;
    text-align: left;
    line-height: 1.2;
    font-size: 2rem;
    margin-bottom: 20px;
  }

  @media ${props => props.theme.breaks.down('md')} {
    width: 90%;
    padding-left: 0;
  }

`

const LinkButton = styled(Link)`
  text-decoration: none;
  display: inline-block;
  padding: 15px 5px;
  background-color: ${props => props.theme.colors.body};
  color: ${props => props.theme.colors.dark};
  width: 100%;
  cursor: pointer;

  &:hover {
    background-color: ${props => props.theme.colors.bodyLight};
  }
`


const FixedFooter = styled.div`
  height: 100px;
  width: 100%;
  /* min-width: ${props => props.theme.maxWidthMd};
  max-width: 1400px; */
  margin: 60px auto 0 auto;
  padding-left: 40px;
  position: relative;
  background-color: ${props => props.theme.colors.dark};

  @media ${props => props.theme.breaks.down('md')} {
    padding-left: 0;
  }
`


const ButWhyPage = ({ data }) => {


  // const {HeroImage, Heading1, Heading2} = data.strapiHero;
  return (
    
      <Layout color={theme.colors.body} pageTitle="But Why?">
      <Container>
        
        <StorySection className="point-sec">
          <div className="title-cond" style={{width: '70%', margin: '0 auto'}}>
          <TitleCond>But why?</TitleCond>
          </div>

          <StoryContainer>

            <Grid container>
              <Grid item xs={12}><h5>This is the space where we tell you our story. The story of why we’re here. The story that inspired us to inspire you to inspire customers to pay money in exchange for your goods and/or services.</h5></Grid>
            </Grid>

            <Grid container>
              <Grid item xs={12}><p>It was a cold, December day or maybe it was in the middle of March, but it was one day in 2011 when our visionary creative director, Joel Schwartz, said “I’m going to be the creative director of a branding company called Celebrand.” That’s probably not what happened, but what an inspiration, right?</p>

              <p>Joel wanted to help businesses make more money. He was meeting with business owners from all over his local business area and he asked them how much money their businesses were making and they told him how much and he said, “Wow, you should be making more money, and I’m going to help. You see, I’m the creative director of a branding company called Celebrand.” That probably didn’t happen, either.</p>

              <p>There are a lot of people who think that branding a company is just writing an “About Us” story that’s meaningful to them. The typical customer doesn’t care enough to read it. They care about actions. They care about the experience. Remember that thing you heard as a kid, that actions speak louder than words? It’s accurate.</p>

              <p>There’s more than one way to tell a story. We really do want to help businesses. That’s why we do what we do.</p>
              </Grid>
            </Grid>
            

            <Grid container className="links" justify="center" spacing={8}>
              <Grid item xs={12} lg={4}><LinkButton to="/#celebrand">Read the Real Story</LinkButton></Grid>
              <Grid item xs={12} lg={4}><LinkButton to="/contact-us">Tell Your Own</LinkButton></Grid>
            </Grid>
          </StoryContainer>
          
        
         <FixedFooter>
          <SectionFooter color={theme.colors.body}></SectionFooter>
          
         </FixedFooter>

         </StorySection>

        
      </Container>
      </Layout>
    
)
}

export default ButWhyPage;